import React from 'react';
import Img from "gatsby-image";
import {BenefitsSection} from "../../styles/BenefitsStyles";


export default function BenefitsTales({ data, pageContext }) {
  return (
    <BenefitsSection>
      <h2>{data.title[pageContext]}</h2>
      <div className="benefits-container">
        {data.benefits &&
        data.benefits.map((benefit, index) => (
          <div className="box" key={index}>
            <Img fixed={benefit.image.asset.fixed}/>
            <div className="text">
              <h5>{benefit.title[pageContext]}</h5>
              <p>{benefit.description[pageContext]}</p>
            </div>
          </div>
        ))}
      </div>
    </BenefitsSection>
  );
}
