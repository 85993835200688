import styled from 'styled-components';

export const BenefitsSection = styled.section`
  padding: 40px 50px 80px;
  @media (max-width: 1023px) {
    padding: 0 25px 40px  ;
  }
  .benefits-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 40px 0;
    gap: 40px;
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px 80px;
    }
    .box {
      @media (max-width: 1023px) {
        display: flex;
        gap: 25px;
      }
      .gatsby-image-wrapper {
        @media (max-width: 768px) {
          width: 80px !important;
          height: auto !important;
          max-height: 80px !important;
        }
      }
      h5 {
        margin: 25px 0;
        @media (max-width: 1023px) {
          margin: 0 0 25px;
        }
        @media (max-width: 768px) {
          margin: 0 0 15px;
        }
      }
      .text {
        flex: 1 1 0;
      }
    }
  }
`;