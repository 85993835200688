import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { LogosSection } from "../../styles/LogosStyles";
import Img from "gatsby-image";

export default function Logos({ data, pageContext }) {
  const slickSettings = {
    accessibility: true,
    className: "slider variable-width",
    dots: false,
    draggable: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 5,
    speed: 500,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          draggable: true,
          infinite: false,
          swipeToSlide: true,
        }
      }
    ]
  };
  return (
    <LogosSection>
      <h2>{data.title[pageContext]}</h2>
      <Slider {...slickSettings}>
        {data.logoAndLink &&
        data.logoAndLink.map((logo, index) => (
          <div key={index}>
            <span id={`link_${index}`}>Link to the {logo.title} page.</span>
            <a href={logo.link} target="_blank" rel="noreferrer" aria-labelledby={`link_${index}`}>
              <Img fluid={logo.image.asset.fluid}
                   style={{
                     width: logo.image.asset.metadata.dimensions.width,
                     height: logo.image.asset.metadata.dimensions.height
              }} />
            </a>
          </div>
        ))}
      </Slider>
    </LogosSection>
  )
}