import styled from 'styled-components';

export const OfferSection = styled.section`
  padding: 80px 50px 40px;
  @media (max-width: 1023px) {
    padding: 0 25px 40px;
  }
  .section-block {
    display: flex;
    column-gap: 10%;
    row-gap: 40px;
    margin-bottom: 80px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
    &.dev {
      @media (max-width: 1023px) {
        flex-direction: column;
      }
    }
    &.copy {
      @media (max-width: 1023px) {
        flex-direction: column-reverse;
      }
    }
      
    .section-text {
      width: 50%;
      @media (max-width: 1023px) {
        width: 100%;
      }
    }
    .button {
      display: inline-block;
      margin-top: 40px;
    }

    .gatsby-image-wrapper {
      width: 40% !important;
      @media (max-width: 1023px) {
        width: 100% !important;
      }
    }
  }
`;