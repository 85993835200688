import styled from 'styled-components';

export const AboutSection = styled.section`
  padding: 80px 50px 100px;
  text-align: center;
  @media (max-width: 1023px) {
    padding: 50px 25px 80px;
  }
  h2 {
    margin: 0 auto;
  }
  .subtitle {
    margin-bottom: 50px;
  }
  .subtitle, .description {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
`;